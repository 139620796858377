import { NineProgressBar } from "@9amhealth/wcl/generated/react";
import styled from "@emotion/styled";
import clsx from "clsx";
import type { FC, RefObject } from "react";
import React, { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  APP_BREAKPOINT,
  APP_CONTEND_WIDTH_LIFESTYLE
} from "src/constants/layout";
import translate from "src/lib/translate";
import HistoryBloc from "src/state/HistoryBloc/HistoryBloc";
import { useBloc } from "src/state/state";
import CustomSuspense from "src/ui/components/CustomSuspense/CustomSuspense";
import InDialog from "src/ui/components/InDialog/InDialog";

const AsyncCustomQuestionnaire = React.lazy(
  async () =>
    import("src/ui/components/CustomQuestionnaire/CustomQuestionnaire")
);

//#region Styled components
export const CenterQuestionnaireContent = styled.div`
  label: CenterQuestionnaireContent;
  display: grid;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  padding: 0 0.5rem;
  padding-bottom: calc(var(--keyboard-height, 50vh) + 2rem);

  > div {
    width: 100%;
  }
  nine-funnel-step,
  .small-title {
    width: min(100%, 100vw, ${APP_CONTEND_WIDTH_LIFESTYLE}px);
    --margin-top: 1.5rem;
    @media screen and (min-width: ${APP_BREAKPOINT}px) {
      --margin-top: 4rem;
    }

    * {
      text-align: center;
    }

    &.text-left * {
      text-align: left;
    }

    h4 {
      --font-family: var(--font-family-serif);
      --font-size: 2rem;
      --font-weight: 300;
      --line-height: 120%;
      --spacing: -0.015em;
    }

    h5 {
      font-size: 1rem;
      font-weight: 400;
      color: var(--color-charcoal-80);
      margin-top: -0.6em;
      margin-bottom: 1.5rem;
    }

    textarea {
      font-size: 1.5rem;
    }

    p,
    li {
      font-size: 1.5rem;
      line-height: 150%;
      color: var(--color-charcoal-80);
    }

    p {
      margin: 0 0 1rem;

      &:first-child {
        margin: 0 0 1rem;
      }

      &:last-child {
        margin: 0;
      }
    }

    .step-button {
      margin-top: 2.5rem;
    }

    .aspect {
      margin-bottom: 2.5rem;

      img {
        aspect-ratio: 16/9;
        border-radius: 0.5rem;
      }
    }

    .rounded {
      margin-bottom: 1.5rem;

      img {
        border-radius: 50%;
        width: 6.5rem;
        aspect-ratio: 1;
        background: none;
      }
    }

    .icon {
      margin-top: 2rem;
      margin-bottom: 1rem;

      img {
        width: 4rem;
        aspect-ratio: 1;
        background: none;
      }
    }

    /* Add padding to h4 on screen with icon */
    .icon + div > h4 {
      padding: 0 1.5rem;
    }

    ol,
    ul {
      margin: 0 0 1rem;

      &:first-child {
        margin: 0 0 1rem;
      }

      &:last-child {
        margin: 0;
      }
    }

    ol {
      padding-left: 2rem;

      li {
        &::marker {
          color: var(--color-sunrise-orange);
        }
      }
    }

    li {
      margin: 0 0 0.5rem;

      &:last-child {
        margin: 0;
      }
    }

    .media-player {
      margin-top: 1.5rem;
      margin-bottom: 2rem;
      border-radius: 0.5rem;
    }
  }

  &.first-step {
    h4 {
      --font-size: 2.5rem;
      --line-height: 110%;
    }
  }
`;

const ProgressBar = styled(NineProgressBar)`
  position: absolute;
  inset: 0;
`;
//#endregion

const LifestyleProgramDialog: FC<{
  returnUrl?: string;
}> = ({ returnUrl }) => {
  const [historyState] = useBloc(HistoryBloc);

  const [progress, setProgress] = useState(0);

  const [query] = useSearchParams();
  const questionIdQuery = query.get("questionnaireId") ?? "";
  const title = query.get("title");

  const [overlayRef, setOverlayRef] =
    useState<RefObject<HTMLIonModalElement>>();
  const navigate = useNavigate();

  const preOpenAppPage = useMemo<string>(() => {
    if (returnUrl) return returnUrl;
    // find last url that starts with "/app", but does not include "/app/questionnaire"
    const justPathnames = historyState.history
      .map(({ pathname }) => pathname)
      .reverse();
    const appPage = justPathnames.find(
      (url) => url.startsWith("/app") && !url.startsWith("/app/questionnaire")
    );

    return appPage ?? "/app";
  }, [historyState]);

  const handleClose = () => {
    if (overlayRef?.current) {
      void overlayRef.current.dismiss();
    }
    navigate(preOpenAppPage);
  };

  const updateProgress = (step: number, total: number) => {
    setProgress((step / total) * 100);
  };

  return (
    <InDialog
      onClose={handleClose}
      title={title ?? translate("lifestyleProgram")}
      setRef={setOverlayRef}
      returnUrl={returnUrl ?? preOpenAppPage}
      backdropDismiss={false}
      width="min(calc(100% - 4rem), 120rem)"
      height="min(calc(100% - 4rem), 70rem)"
    >
      {progress !== 0 && <ProgressBar progress={`${progress}%`}></ProgressBar>}

      <CenterQuestionnaireContent
        className={clsx({
          "first-step": progress === 0
        })}
      >
        <CustomSuspense>
          <AsyncCustomQuestionnaire
            autoSkip={true}
            resetScrollOnEachStep
            id={questionIdQuery}
            useRouting
            onSubmit={async (): Promise<boolean> => {
              // setCompleted(true);
              return false;
            }}
            onStep={updateProgress}
          />
        </CustomSuspense>
      </CenterQuestionnaireContent>
    </InDialog>
  );
};

export default LifestyleProgramDialog;
